<template>
  <section class="pagination__section sixteen wide center aligned column">
    <paginate
      v-model="actualPage"
      :page-count="pages"
      :force-page="count"
      :click-handler="clicked"
      :container-class="'ui pagination menu'"
      :page-class="'item'"
      :next-class="'item'"
      :prev-class="'item'"
      :prev-text="'Anterior'"
      :next-text="'Próxima'"
    ></paginate>
  </section>
</template>

<script>
import Vue from "vue";
import Paginate from "vuejs-paginate";

Vue.component("paginate", Paginate);

export default {
  name: "Pagination",
  components: {},
  props: {
    page: Number,
    pages: Number,
    count: Number,
    clicked: Function,
  },
  data() {
    return {
      actualPage: this.page,
    };
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style lang="scss">
@import "~@/assets/styles/globals/colors";

.pagination__section {
  ul.pagination {
    padding-left: 0;
    li.item {
      padding: 0 !important;
      a {
        color: $color-primary;
        padding: 0.92857143em 1.14285714em;
        &:focus {
          outline: none;
        }
      }
      &.active {
        background-color: $color-secondary !important;
        a {
          color: #fff;
        }
      }
      &.disabled {
        opacity: 0.7;
        a {
          cursor: text;
        }
      }
    }
  }
}
</style>
